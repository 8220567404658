import React from 'react'
import clients from './clientIcons.js'

const Clients = () => {

  const shuffle = (array) => {
      let counter = array.length;

      // While there are elements in the array
      while (counter > 0) {
          // Pick a random index
          let index = Math.floor(Math.random() * counter);

          // Decrease counter by 1
          counter--;

          // And swap the last element with it
          let temp = array[counter];
          array[counter] = array[index];
          array[index] = temp;
      }

      return array;
  }

  return(
    <section id="clients" className="w-100 bg-near-black mt6 pv5">
      <div className="f2 mt3 mb5 pb3 tc moon-gray">Our Clients</div>
      <div id="clients-row-1" className="flex flex-wrap justify-around mh6-l mh4-ns mh3 mv3">
        { shuffle(clients).map((brand, i) => (
          <article key={brand.name} className="mv2 ph3 pv2 flex flex-column justify-center items-center">
            <img className="mw4" alt={`${brand.name}`} src={brand.path} />
          </article>
        ))}
      </div>
    </section>
  )
}

export default Clients

import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Nav, Footer, Landing, Quote, FAQ } from './components'

const App = () => {
  return (
    <Router >
      <div>
        <Nav />
        <div id="body">
          <Route path="/" exact component={Landing} />
          <Route path="/quote" exact component={Quote} />
          <Route path="/faq" exact component={FAQ} />
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App

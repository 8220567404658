import React, { Fragment } from 'react'
import { screenPrintingData, embroideryData } from './Feature/featureData.js'
import {
  Splash,
  InfoSpan,
  Products,
  Shop,
  Clients,
  Feature,
} from '../index'

// const Break = () => {
//   return (<div className="mv6 bb mh7-l mh5 b--mid-gray" />)
// }

const Landing = () => {
  return(
    <Fragment>
      <Splash />
      <Feature {...screenPrintingData} />
      <InfoSpan />
      <Feature {...embroideryData} />
      <Clients />
      <Shop />
      <Products />
    </Fragment>
  )
}

export default Landing

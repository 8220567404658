import React, { useState, useEffect } from "react";
import Select from "react-select";
import isEmail from "validator/lib/isEmail";
import axios from "axios";
// import MultiFileUploader from './MultiFileUploader';
import { printRanges } from "./formOptions";
import { Link } from "react-router-dom";

const Quote = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [details, setDetails] = useState("");
    const [orderType, setOrderType] = useState({
        label: "Screenprinting",
        value: "screenprinting"
    });
    const [quantity, setQuantity] = useState(null);
    //   const [uploadedFiles, setUploadedFiles] = useState([]);
    const [formComplete, setFormComplete] = useState(false);
    const [formError, setFormError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [quantityError, setQuantityError] = useState(null);
    //   const [filesTooLarge, setFilesTooLarge] = useState(null);
    const [formCompleteMessage, setFormCompleteMessage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        /*window.scrollTo(0,0)*/
    }, []);

    const styles = {
        option: styles => ({
            ...styles,
            fontWeight: "600",
            fontSize: "1.25rem",
            color: "#222"
        }),
        valueContainer: styles => ({
            ...styles,
            fontWeight: "600",
            fontSize: "1.25rem",
            padding: ".5rem 1rem"
        })
    };

    const buildFormData = () => {
        const formData = new FormData();

        formData.append("name", name);
        formData.append("email", email);
        formData.append("details", details);
        formData.append("orderType", orderType.label);
        if (quantity) formData.append("quantity", quantity.label);
        else formData.append("quantity", null);

        // for (let file of uploadedFiles) {
        //   formData.append('designs', file);
        // }

        return formData;
    };

    const verifyFormData = formData => {
        let newError = false;
        for (let value of formData) {
            if (value[0] === "name") {
                if (value[1] === "") {
                    newError = true;
                    setNameError({
                        message: "Name is required."
                    });
                } else {
                    setNameError(null);
                }
            } else if (value[0] === "email") {
                if (value[1] === "") {
                    newError = true;
                    setEmailError({
                        message: "Email is required."
                    });
                } else if (!isEmail(value[1])) {
                    newError = true;
                    setEmailError({
                        message: "Valid email is required."
                    });
                } else {
                    setEmailError(null);
                }
            } else if (value[0] === "quantity") {
                if (value[1] === "null") {
                    newError = true;
                    setQuantityError({
                        message: "Quantity is required."
                    });
                } else {
                    setQuantityError(null);
                }
            }
        }

        return newError;
    };

    const onSubmit = e => {
        e.preventDefault();
        // if (uploadedFiles === null) return;

        const formData = buildFormData();
        if (verifyFormData(formData)) {
            setFormError({
                message:
                    "Uh oh, please scroll back up and make sure the form is complete!"
            });
        } else {
            setFormError(null);
            sendQuote(formData);
        }
    };

    const sendQuote = async formData => {
        setIsUploading(true);
        const config = { headers: { "content-type": "multipart/form-data" } };
        const response = await axios.post("/api/quote", formData, config);
        if (response.status === 200) {
            setFormComplete(true);
            document.getElementById("quote").scrollIntoView();
        } else if (response.status === 203) {
            setFormCompleteMessage(response.data.message);
            //   setFilesTooLarge(response.data.largeFiles);
            setFormComplete(true);
            document.getElementById("quote").scrollIntoView();
        } else {
            setFormError({
                message:
                    "Uh oh, something went wrong. Please email us at support@theinprint.com with your order details and we'll be happy to help!"
            });
        }

        setIsUploading(false);
    };

    const renderError = error => {
        if (error === null) return;
        return (
            <div className="error-message mh3 mv2 red">
                {error && error.message}
            </div>
        );
    };

    //   const renderFilesTooLargeOnComplete = () => {
    //     return (
    //       <>
    //         <div className="red f4 b mt4">Please note! </div>
    //         <div className="tc">{formCompleteMessage}</div>
    //         <ul>
    //           {filesTooLarge.map((file) => {
    //             return <li className="red">{file}</li>;
    //           })}
    //         </ul>
    //       </>
    //     );
    //   };

    const renderComplete = () => {
        return (
            <>
                <header className="w-100 pv3 mb3 self-start bb b--dark-gray">
                    <div className="light-silver f3 fw6 mv2">
                        Your quote request has been submitted!
                    </div>
                </header>
                <div className="f4 pa3 tc">
                    Thanks for reaching out to us! You should receive an email
                    at {email} within the next 2-3 business days.
                </div>
                {/* {filesTooLarge && renderFilesTooLargeOnComplete()} */}
                <div className="flex justify-around">
                    <Link
                        to="/"
                        className="link ph3 mv4 mh4 ba b--cyan hover-white cyan"
                    >
                        <div className="f5 mv1 fw6">Return to Homepage</div>
                    </Link>
                    <Link
                        to="/faq"
                        className="link ph3 mv4 mh4 ba b--cyan hover-white cyan"
                    >
                        <div className="f5 mv1 fw6">Read our FAQ</div>
                    </Link>
                </div>
            </>
        );
    };

    const renderSubmit = () => {
        return (
            <section className="mv3">
                <button
                    onClick={onSubmit}
                    disabled={isUploading}
                    className={`pv2 ph4 f4 fw6 br2 b--mid-gray  ${
                        isUploading
                            ? "silver bg-dark-gray pointer-error"
                            : "near-black hover-bg-cyan-to-dark-cyan hover-brightness-125 shadow-cyan pointer"
                    }`}
                >
                    {isUploading ? "Uploading images..." : "Submit for Quote"}
                </button>
                <div className="f6 mt3">
                    {isUploading
                        ? "Your images are being sent. Please wait..."
                        : ""}
                </div>
            </section>
        );
    };

    const renderForm = () => {
        return (
            <>
                <header className="w-100 pv3 mb3 self-start bb b--dark-gray">
                    <div className="light-silver f3 fw6 mv2">
                        Submit your designs below for a free quote
                    </div>
                </header>
                <form className="flex flex-column justify-center items-center self-center w-100 ph3-ns">
                    <section className="grid-1-3 mv2 justify-center items-center w-100">
                        <label className="f4">Name</label>
                        <input
                            type="text"
                            name="name"
                            className="mh3 ph3 pv2 br1 bg-near-white near-black fw6 f4"
                            onChange={e => setName(e.target.value)}
                        />
                        {renderError(nameError)}
                    </section>

                    <section className="grid-1-3 mv2 justify-center items-center w-100">
                        <label className="f4">Email</label>
                        <input
                            type="email"
                            name="email"
                            className="mh3 ph3 pv2 br1 bg-near-white near-black fw6 f4"
                            onChange={e => setEmail(e.target.value)}
                        />
                        {renderError(emailError)}
                    </section>

                    <section className="grid-1-3 mv2 justify-center items-center w-100">
                        <label className="f4 w-100">Print Type</label>
                        <Select
                            value={orderType}
                            onChange={selectedOption =>
                                setOrderType(selectedOption)
                            }
                            options={[
                                {
                                    label: "Screenprinting",
                                    value: "screenprinting"
                                },
                                { label: "Embroidery", value: "embroidery" },
                                { label: "Both", value: "both" }
                            ]}
                            styles={styles}
                            isSearchable={false}
                            className="mh3"
                        />
                    </section>

                    <section className="grid-1-3 mv2 justify-center items-center w-100">
                        <label className="f4 w-100">Quantity</label>
                        <Select
                            value={quantity}
                            name="quantity"
                            onChange={selectedOption =>
                                setQuantity(selectedOption)
                            }
                            placeholder="Quantity..."
                            options={printRanges}
                            styles={styles}
                            isSearchable={false}
                            className="mh3"
                        />
                        {renderError(quantityError)}
                    </section>

                    <section className="grid-1-3 mv2 justify-center items-center w-100">
                        <label className="f4 self-start">Order Details</label>
                        <textarea
                            id="details"
                            name="details"
                            rows="6"
                            className="mh3 ph3 pv3 br1 bg-near-white near-black f4"
                            onChange={e => setDetails(e.target.value)}
                        />
                    </section>
                    {/* 
          <MultiFileUploader
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          /> */}

                    {renderError(formError)}

                    {renderSubmit()}
                </form>
            </>
        );
    };

    return (
        <main id="quote" className="mb6">
            <header className="pv4 tc bg-gray flex justify-center items-center">
                <div className="bg-spotlight-dark">
                    <div className="f3 light-gray">You're almost there...</div>
                    <div className="f3 f2-l fw6 near-white">
                        Get a Free Quote on your Designs
                    </div>
                </div>
            </header>
            <article className="flex-center ">
                <div className="flex flex-column items-center mt4-ns mh6-l mh4-m pb4 bg-near-black ph4 pv2 tl mw7 w-100">
                    <p className="measure-wide tc f4">
                        Send us an email at{" "}
                        <a
                            className="cyan b hover-brightness-75"
                            href="mailto:info@theinprint.com"
                        >
                            info@theinprint.com
                        </a>{" "}
                        with your designs or ideas and we'll help get you what
                        you're looking for!
                    </p>
                    {/* {formComplete ? renderComplete() : renderForm()} */}
                </div>
            </article>
        </main>
    );
};

export default Quote;

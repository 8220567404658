import React, { useState, useEffect } from 'react'
import QuestionAnswer from './QuestionAnswer'
import questionAnswers from './questions'
import ReturnToTop from './ReturnToTop'

const FAQ = () => {
  const [vScroll, setVScroll] = useState(window.scrollY)

  useEffect(() => {
    const setVScrollToWindow = () => setVScroll(window.scrollY)
    window.addEventListener("scroll", setVScrollToWindow)
    return(() => window.removeEventListener("scroll", setVScrollToWindow))
  })

  return(
    <main id="faq" className="mb6">
      <ReturnToTop vScroll={vScroll} />
      <header className="pv3 tc bg-shipping-room flex justify-center items-center">
        <div className="bg-spotlight-dark">
          <div className="f3 f2-l fw6 text-shadow-1 near-white mb2">InPrint Screen Printing & Embroidery FAQ</div>
          <div className="f3 text-shadow-1 light-gray">Have questions? We've got answers!</div>
        </div>
      </header>

      <aside role="menu" className="pv3 bg-near-black near-white">
        <div id="faq-header" className="anchor" />
        <div className="bb b--mid-gray f3 fw7 titillium pv4 mh5-l ph3">Frequently Asked Questions</div>
        <div className="grid-50-50-ns mt4 mb5 ph6-l ph4-m ph3 ls-raquo2">
          {questionAnswers.map(qA => (
            <div className="list-item mt4 mh3 hover-shadow">
              <a className="f4 fw6 link pl3 w-100 near-white" href={`#${qA.anchor}`}>{qA.question}</a>
            </div>)
          )}
        </div>
      </aside>

      <section className="mh7-l mv5 flex flex-column items-center">
        {questionAnswers.map(qA => {
          return (
            <div className="mb5 question mw7 w-100">
              <div id={qA.anchor} className="anchor" />
              <QuestionAnswer qA={qA} />
            </div>
          )
        })}
      </section>
    </main>
  )
}

export default FAQ

import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const GoogleMap = ({ google }) => {
  return (
    <div id="map">
      <Map google={google}  zoom={14}
        initialCenter={{lat: 45.4714979, lng: -73.5936057}}>
        <Marker
          title={'InPrint - Screen Printing and Embroidery Shop'}
          name={'SOMA'}
          position={{lat: 45.4714979, lng: -73.5936057}}
        />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyAFsmfNkF9bFRtjbkRPXlzMBYi7Qh8QEAw")
})(GoogleMap)

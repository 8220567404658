import React from 'react'
import { Link } from 'react-router-dom'

export default [
  {
    anchor: "submit-order",
    question: "How do I Submit an order?",
    answer: (
      <div>
        Use our <Link className="link yellow fw6 hover-underline" to="/quote">Quote</Link>&nbsp;
        form, include as much information as you can, and upload your artwork.
        Our service department will email you with any questions we have
        and provide a quote when ready.
      </div>
    )
  },
  {
    anchor: "turn-around-time",
    question: "What is your turn-around time?",
    answer: (
      <div>
        7-10 business days, we do offer Rush Service for an extra free.
      </div>
    )
  },
  {
    anchor: "available-garments",
    question: "What garments can I buy from you?",
    answer: (
      <div>
        We source from all brands common in the industry and some more
        specialty and high end. T-shirts, hoodies, crewnecks, long sleeves,
        tank tops, beanies, snapbacks and tote bags are our best sellers.
      </div>
    )
  },
  {
    anchor: "artwork-approval",
    question: "How do I submit my artwork?",
    answer: (
      <div>
        In Illustrator all the strokes and fonts need to be outlined with no
        embedded pixel images. In Photoshop, artwork needs to be sized to your
        final print and rendered at 300 DPI, ideally submitted with art layers
        preserved. We offer in-house graphic services to get your art ready if you need.
      </div>
    )
  },
  {
    anchor: "drop-ins",
    question: "Can I stop by to ask questions and see apparel?",
    answer: (
      <div>
        Absolutely! We have a complete showroom of blank apparel and headwear,
        as well as a printed portfolio to look at.
      </div>
    )
  },
  {
    anchor: "minimum-order",
    question: "What is your minimum order?",
    answer: (
      <div>
        Our minimum order starts at 25 pieces for screen printing, and 18 pcs for embroidery.
      </div>
    )
  },
  {
    anchor: "listed",
    question: "What costs can I expect on my order?",
    answer: (
      <div>
        For pricing please contact us! Most of the costs are in buying the garments
        and preparing the screens to print. Less screens (colours) means lower costs on you.
      </div>
    )
  },
  {
    anchor: "supply",
    question: "Can I supply my own items to print on or embroider?",
    answer: (
      <div>
        Yes you can. We reserve the right to reject your garments if we
        believe we cannot provide a quality print or embroidery.
      </div>
    )
  },
  {
    anchor: "proof",
    question: "Can I get a digital proof or a printed sample before my order?",
    answer: (
      <div>
        We will email you a digital mockup (teckpack) of your artwork.
        This techpack shows art specifications, placement on the garments,
        and colours to be printed and needs your approval before we print.
        Printed samples are possible, but will incur separate sampling fees.
      </div>
    )
  },
]

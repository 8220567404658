const clients = [
    {
      name: "Joe Beef",
      path: "./imgs/clients/JoeBeef-logo.png"
    },
    {
      name: "Cirque du Soleil",
      path: "./imgs/clients/cirque-logo.png"
    },
    {
      name: "Dare2Care",
      path: "./imgs/clients/dare2care-logo.png"
    },
    {
      name: "Dime",
      path: "./imgs/clients/Dime-logo.png"
    },
    {
      name: "Lopez",
      path: "./imgs/clients/Lopez-logo.png"
    },
    {
      name: "Mehrathon",
      path: "./imgs/clients/mehrathon-logo.png"
    },
    {
      name: "Primitive",
      path: "./imgs/clients/primitive.png"
    },
    {
      name: "Saintwoods",
      path: "./imgs/clients/Saintwoods-logo.png"
    },
    {
      name: "Stay Home Club",
      path: "./imgs/clients/StayHomeClub-logo.png"
    },
    {
      name: "Style & Ease",
      path: "./imgs/clients/style&ease-logo.png"
    },
    {
      name: "Vans",
      path: "./imgs/clients/Vans-Logo.png"
    },
    {
      name: "Kebbek",
      path: "./imgs/clients/Kebbek.png"
    },
  ]

  export default clients

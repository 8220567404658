const screenPrintingData = {
  title: "SCREENPRINTING",
  p1: "We are Montreal's top choice to screen print t-shirts, hoodies, sweatshirts, and every garment you can imagine. Screen printing is perfect for high volume orders and achieves fine detailed art in every print. With advanced printing technology and fast turn around our prints are top quality at the best price.",
  p2: "We print with plastisol inks, water base inks, heat transfers, discharge, sublimation, foils, metallic ink and more. Our in house showroom has samples of each process so you can make the best choice for your brand, event, festival, and merchandise.",
  images: [
    {
      alt: "inprint shirt",
      path: "./imgs/gallery/InPrintColor.jpg"
    },
    {
      alt: "sprite shirt",
      path: "./imgs/gallery/BeautifulSpitfire-Shirt.jpg"
    },
    {
      alt: "Bats shirt",
      path: "./imgs/gallery/Bats_shirt.jpg"
    },
    {
      alt: "Danny Rebel shirt",
      path: "./imgs/gallery/DannyRebel_shirt.jpg"
    },
    {
      alt: "Dime Dragon shirt",
      path: "./imgs/gallery/dimeDragon_shirt.jpg"
    },
    {
      alt: "Joe Beef shirt",
      path: "./imgs/gallery/JoeBeef-shirt.jpg"
    },
    {
      alt: "mandala shirt",
      path: "./imgs/gallery/mendala_Shirt.jpg"
    },
    {
      alt: "sabotage shirt",
      path: "./imgs/gallery/sabotage_Shirt2.jpg"
    },
  ]
}

const embroideryData = {
  title: "EMBROIDERY",
  subtitle: "Show off your brand with custom apparel",
  p1: "",
  p2: "Offering a combination of embroidered and screen printed apparel in your line is a great way to diversify your brand's identity and your company's look. It's the perfect long-lasting textured application for art on hoodies, crew necks, jackets, caps, beanies and more.",
  images: [
    {
      alt: "Taco",
      path: "./imgs/embroidery/Taco.jpg"
    },
    {
      alt: "Large Embroidery",
      path: "./imgs/embroidery/LargeEmbroidery.jpg"
    },
    {
      alt: "InPrint Embroidery",
      path: "./imgs/embroidery/InPrint-embroidery.jpg"
    },
    {
      alt: "Mehrathon",
      path: "./imgs/embroidery/MehraOTHon.jpg"
    },
    {
      alt: "Embroidery Machine",
      path: "./imgs/embroidery/embroiderymachine.jpg"
    },
    {
      alt: "Mikmaki",
      path: "./imgs/embroidery/Mikmaki_embroidery.jpg"
    },
    {
      alt: "Embroidery Threads Wall",
      path: "./imgs/embroidery/embroiderythreadswall.jpg"
    },
    {
      alt: "PT Cruiser",
      path: "./imgs/embroidery/PTcruiser.jpg"
    },
  ],
}

export {
  screenPrintingData,
  embroideryData
}

import React from 'react'

const InPrintImg = ({img}) => {
  return (
    <React.Fragment>
      <img
        width="512px"
        height="384px"
        className="w-100 h-100 ma0 pa0 br2-ns ba-ns bt bb bw1-ns b--mid-gray shadow-3"
        alt={`InPrint ${img}`}
        src={`./imgs/inprint-brand-${img}.jpg`}
      />
    </React.Fragment>
  )
}

export default InPrintImg

import React from 'react'
import SlickSlider from './SlickSlider'

const Feature = ({title, subtitle, images, p1, p2}) => {
  return(
    <section className="flex flex-column">
      <header className="lato ma4 bl bw2 b--cyan pl3 pv1">
        <div className="f2 pb2">{title}</div>
      </header>
      <SlickSlider images={images} />
      <article className="mt5 flex flex-column justify-start items-start flex-row-l justify-around-l flex-auto">
        {p1 && <div className="mt4 mh4 f4 tl lh-copy measure bl bw2 b--cyan pl3 pv1">{p1}</div>}
        <div className="mt4 mh4 f4 tl lh-copy measure bl bw2 b--cyan pl3 pv1">{p2}</div>
      </article>
    </section>
  )
}

export default Feature

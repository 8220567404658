import React from 'react'

const QuestionAnswer = ({qA}) => {

  return (
    <article className="near-white br2-l overflow-hidden b--near-black ba heavy-shadow">
      <header className="bg-dark-gray w-100 ph4 pv3 header-glow-shadow bb b--near-black">
        <h2>{qA.question}</h2>
      </header>
      <section className="f4 ph4 pv4 bg-moon-gray near-black inset-shadow-1">
        {qA.answer}
      </section>
    </article>
  )
}

export default QuestionAnswer

const products = [
  {
    name: "Alstyle",
    path: "./imgs/apparel/Alstyle.png"
  },
  {
    name: "American Apparel",
    path: "./imgs/apparel/AA.png"
  },
  {
    name: "Bella Canvas",
    path: "./imgs/apparel/bellacanvas.png"
  },
  {
    name: "Champion",
    path: "./imgs/apparel/champion.png"
  },
  {
    name: "Dickies",
    path: "./imgs/apparel/Dickies-logo.png"
  },
  {
    name: "Ecorite",
    path: "./imgs/apparel/ecorite-logo.png"
  },
  {
    name: "Flexfit",
    path: "./imgs/apparel/flexFit.png"
  },
  {
    name: "Gildan",
    path: "./imgs/apparel/Gildan.png"
  },
  {
    name: "HS",
    path: "./imgs/apparel/HS.png"
  },
  {
    name: "Jerico",
    path: "./imgs/apparel/jerico.png"
  },
  {
    name: "MilTex",
    path: "./imgs/apparel/Miltex.png"
  },
  {
    name: "Next Level",
    path: "./imgs/apparel/nextlevel.png"
  },
  {
    name: "Rabbit Skins",
    path: "./imgs/apparel/rabbitSkins.png"
  },
  {
    name: "Yupoong",
    path: "./imgs/apparel/yupoong.png"
  },
]

export default products

import React from 'react'

const InfoSpan = () => {
  return (
    <article className="flex flex-column justify-around items-center mt5 pt5 pb5 bg-near-black near-white">
      <div className="mh6-l">
        <img
          className="w-100 h-100 ma0 pa0"
          alt={`InPrint Banner - Screenprinting process`}
          src={`./imgs/inprint-banner.png`}
          />
      </div>
      <aside className="mt4 mh4 flex flex-column justify-around items-center
        items-start-l flex-row-ns flex-wrap-m justify-center-m">
        <section className="flex flex-column justify-center flex-auto mv4
          pv2 ph3 mr4 bl b--cyan bw2">
          <div className="bg-mid-gray moon-gray br-circle-4 dn justify-center items-center">
            <i className="fas fa-tshirt f2 fa-fw"></i>
          </div>
          <div className="pb2 f3 b">Legendary</div>
          <div className="f4 mt3 flex-auto lh-copy measure">We raised the
            bar in customer service in the industry, now we exceed it.</div>
        </section>

        <section className="flex flex-column justify-center flex-auto mv4
          pv2 ph3 mr4 bl b--cyan bw2">
          <div className="bg-mid-gray moon-gray br-circle-4 dn justify-center items-center">
            <i className="fas fa-brush f2 fa-fw"></i>
          </div>
          <div className="pb2 f3 b">Customer</div>
          <div className="f4 mt3 flex-auto lh-copy measure">We treat you like
            you are the next big thing! If you already are the next big thing, lucky us. </div>
        </section>

        <section className="flex flex-column justify-center flex-auto mv4
          pv2 ph3 mr4 mr0-l ml4-l bl b--cyan bw2">
          <div className="bg-mid-gray moon-gray br-circle-4 dn justify-center items-center">
            <i className="fas fa-clock f2 fa-fw"></i>
          </div>
          <div className="pb2 f3 b">Service</div>
          <div className="f4 mt3 flex-auto lh-copy measure">Ordering garments, preparing your art work,
            approving mock-ups and sampling, we keep you involved</div>
        </section>
      </aside>
    </article>
  )
}

export default InfoSpan

import React from 'react'

const Splash = () => {
  return(
    <main id="splash" className="mt5 pt5 pb5 flex flex-column items-center justify-center">
      <div className="flex flex-column justify-center items-center">
        <div>
          <img className="mw-100-ns mw5 mv3 mh4-l" alt="logo" src="./imgs/logo.png" />
        </div>
        <section className="mh4-l mv3 flex flex-column items-center justify-center">
          <div className="mt4 near-white text-shadow-1">
            <div className="f2 mr6-l mr4 fw6">Montreal shirts for</div>
            <div className="f2 ml6-l ml4 fw6">a Montreal audience</div>
          </div>
        </section>
      </div>
      <a href="#screen-printing" className="dn link bg-dark-gray light-gray br-100 h2 w2 pa3 fw8">V</a>
    </main>
  )
}

export default Splash

import React, { Component } from 'react'
import GoogleMap from './GoogleMap'

class Footer extends Component {
    render() {
        return (
            <footer id="contact" className="bg-near-black near-white shadow-1 overflow-auto">
                <div className="f2 mv3 mh4">InPrint</div>
                <section className="flex flex-column flex-row-l justify-between bt b--gray mt0-25">
                    <div className="pv4 ph4-ns ph3 flex flex-column flex-row-ns justify-around">
                        <div className="w-100-m mh3">
                            <div className="silver measure-narrow">
                                High quality screen printed apparel for all your corporate and promotional needs.
                            </div>

                            <div className="flex items-center mv3 ml2 gray">
                                <div className="flex justify-center w1"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="list pl4 moon-gray">
                                    <div className="f5 lh-solid mv1">8505 Chem. Delmeade</div>
                                    <div className="f5 lh-solid mv1">Mont-Royal, QC H4T 1M1</div>
                                </div>
                            </div>

                            <div className="flex items-center mt3 ml2 gray">
                                <div className="flex justify-center w1"><i className="fas fa-envelope"></i></div>
                                <a className="f5 lh-solid link moon-gray pl4 hover-cyan" href="mailto:info@theinprint.com">info@theinprint.com</a>
                            </div>

                            <div className="flex items-center mv3 ml2 gray">
                                <div className="flex justify-center w1"><i className="fas fa-phone"></i></div>
                                <a className="f5 lh-solid link moon-gray pl4 hover-cyan" href="tel:+1-514-800-7502">(514) 800-7502</a>
                            </div>

                            <div className="flex flex-column mv4 silver">
                                <div>Follow us on social media</div>
                                <div className="flex items-center mv3 mh3 gray">
                                    <a className="f2 fab fa-instagram link mr3 hover-cyan gray"
                                        href="https://www.instagram.com/theinprintmtl">
                                        <span className="screen-reader-only">
                                            instagram for inprint
                                        </span>
                                    </a>
                                    <a className="f2 fab fa-facebook-square link mh3 hover-cyan gray"
                                        href="https://www.facebook.com/INPrintMontreal/">
                                        <span className="screen-reader-only">
                                            facebook for inprint
                                        </span>
                                    </a>
                                    <a className="f2 fab fa-twitter-square link ml3 hover-cyan gray"
                                        href="https://twitter.com/inprint">
                                        <span className="screen-reader-only">
                                            twitter for inprint
                                        </span>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <div className="w-100-m mt4 mt0-ns mh3">
                            <div className="silver f4 mb3">Opening Hours</div>
                            <div className="grid-1-1 gray mv2 mh2 gap1 moon-gray">
                                <div>Monday</div>
                                <div>9am - 5pm</div>
                                <div>Tuesday</div>
                                <div>9am - 5pm</div>
                                <div>Wednesday</div>
                                <div>9am - 5pm</div>
                                <div>Thursday</div>
                                <div>9am - 5pm</div>
                                <div>Friday</div>
                                <div>9am - 5pm</div>
                                <div>Saturday & Sunday</div>
                                <div>Closed</div>
                            </div>
                        </div>
                    </div>
                    <div className="self-center mv4 mh3-ns w-100 w-auto-l">
                        <GoogleMap />
                    </div>
                </section>


                <aside className="f6 flex justify-center items-center pv3 bg-dark-gray gray w-100">
                    Copyright InPrint © 2019
        </aside>
            </footer>
        )
    }
}

export default Footer

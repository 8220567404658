import React from 'react'
import { Link } from 'react-router-dom'
import FullSlickSlider from './FullSlickSlider'
import shopData from './shopData'

const Shop = () => {

  const clickMenuLink = () => { window.scrollTo(0,0) }

  return(
    <div id="shop">
      <FullSlickSlider images={shopData} />
      <section className="flex flex-column flex-row-l justify-around-l pv4 tc">
        <aside className="pv2 mv3 mh4 flex-auto order-2 order-1-l ba-ns bn-s b--cyan hover-bg-dark-cyan hover-near-black">
          <Link to="/faq" className="link" onClick={clickMenuLink}>
            <header className="f3 near-white mv1">
              Still have questions?
            </header>
            <div className="f5 cyan mv1 fw6">
              Try Our FAQ
            </div>
          </Link>
        </aside>
        <main className="pv2 mv3 mh4 flex-auto order-1 order-2-l ba b--cyan hover-bg-dark-cyan hover-near-black">
          <Link to="/quote" className="link" onClick={clickMenuLink}>
            <header className="f3 near-white mv1">
              Start your order now
            </header>
            <div className="f5 cyan mv1 fw6">
              Get a Free Quote
            </div>
          </Link>
        </main>
      </section>
    </div>
  )
}

export default Shop

import React, { Component } from 'react'
import products from './productIcons.js'

class Products extends Component {

  shuffle = (array) => {
      let counter = array.length;

      // While there are elements in the array
      while (counter > 0) {
          // Pick a random index
          let index = Math.floor(Math.random() * counter);

          // Decrease counter by 1
          counter--;

          // And swap the last element with it
          let temp = array[counter];
          array[counter] = array[index];
          array[index] = temp;
      }

      return array;
  }
  render() {
    return(
      <section id="products" className="pt6 pb5 w-100 bg-near-black">
        <div className="f2 mh2 mb5 fw5 tc moon-gray">High Quality Brands for High Quality Prints</div>
        <div className="flex flex-wrap justify-around mh6-l mh4-ns mh3 mv3">
          { this.shuffle(products).map((brand, i) => (
            <article key={brand.name} className="mv2 ph3 pv2 flex flex-column justify-center items-center">
              <img className="mw4" alt={`${brand.name} available for screenprinting & embroidery.`} src={brand.path} />
            </article>
          ))}
        </div>
      </section>
    )
  }
}

export default Products

import React from 'react'

const ReturnToTop = ({vScroll}) => {

  const scrollIntoView = () => {
    let startPoint = 500
    let endPoint = 1100
    let totalDistance = endPoint - startPoint
    let relativeScroll = vScroll - startPoint

    let distanceScrolled = relativeScroll / totalDistance

    if (vScroll > endPoint) {
      return 100
    }  else if (vScroll > startPoint) {
      return distanceScrolled
    } else {
      return 0
    }
  }

  return(
    <div style={{opacity: scrollIntoView()}} className={`fixed bottom-0 right-0 pa2 ma5-l ma3 flex-center flex-column pointer tr f5 moon-gray bg-near-black br-100 w3 h3`}
      onClick={() => document.getElementById('faq-header').scrollIntoView()}>
      <i className="f2 fas fa-arrow-up fa-fw"></i>
    </div>
  )
}

export default ReturnToTop

import React, { Component } from 'react'
import InPrintImg from './InPrintImg'

class ScreenPrinting extends Component {
  render() {
    return(
      <main>
        <div id="screen-printing" className="anchor order-1" />

        <article className="flex flex-column justify-center items-center flex-row-l justify-around-l items-start-l">
          <aside className="relative mh0 mt5 mt6-l ml5-l mw6 order-2 ">
            <header className="mb4 flex-column justify-center dn tc lato">
              <div className="f3 nowrap">Rapid, High-Volume Screen Printing</div>
              <div className="f3 pt2 light-silver measure">Your designs deserve the best.</div>
            </header>
            <InPrintImg img="screenprinting" />
          </aside>

          <section className="flex flex-column mh5-l mh3 ph3 mt5 mt6-l order-1 order-3-l">
            <header className="lato bl bw2 b--mid-gray pl3 pv1">
              <div className="f4 pv2 light-silver mb1 measure">Your designs deserve the best</div>
              <div className="f3 pb2">Rapid, High-Volume Screen Printing</div>
            </header>

            <main className="mt4 flex flex-column justify-end flex-auto">
              <div className="f4 fw6 lh-copy measure">
                With over ten years of industry experience, we consistently deliver high quality
                prints to ensure your designs stand out.
              </div>
              <div className="mt2-ns f4 tl lh-copy measure">
                Whether you're looking to print a quality zip-up sweater or a simple t-shirt, silk screen
                printing is still considered to be the best method for printing on fabrics and apparel. We will
                help you get the right look and feel for your prints by picking from a selection of various
                inks and printing techniques.
              </div>
            </main>
          </section>
        </article>
      </main>
    )
  }
}

export default ScreenPrinting

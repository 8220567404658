import React from "react";
import Slider from "react-slick";

const SlickSlider = ({ images }) => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 1200,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <div className="relative mw-100">
      <Slider {...settings}>
        {images.map(img =>
          <div className="flex-important justify-center ph3-ns" key={img.alt}>
            <img
              alt={img.alt}
              src={img.path}
              className="w6"
            />
          </div>
        )}
      </Slider>
    </div>
  );
}

export default SlickSlider

const shopData = [
  {
    alt: "large auto",
    path: "./imgs/shop/largeAuto.jpg",
    header: "16 COLOUR AUTO",
    body: "prints 2000 shirts per day",
    bgClass: "bg-production-room",
  },
  {
    alt: "ink room",
    path: "./imgs/shop/inkRoom.jpg",
    header: "ADVANCED INK SOFTWARE",
    body: "matches your colours in the Pantone System",
    bgClass: "bg-ink-room",
  },
  {
    alt: "squirt gun",
    path: "./imgs/shop/squirtGun.jpg",
    header: "IN-HOUSE ART STUDIO",
    body: "brings your shirts to life",
    bgClass: "bg-squirt-gun",
  },
]

export default shopData;
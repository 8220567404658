import React from "react";
import Slider from "react-slick";

const FullSlickSlider = ({ images }) => {
  const settings = {
    arrows: false,
    infinite: true,
    speed: 1200,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <div className="relative mw-100 full-slick">
      <Slider {...settings}>
        {images.map(img =>
        <div className={img.bgClass} key={img.bgClass}>
          <div className="flex-important flex-column justify-center items-center pv6 h-100">
              <div className="flex flex-column justify-center items-center w-100">
                <header className="f3 f1-ns lh-title fw5 tracked-mega near-white tc measure">
                  {img.header}
                </header>
                <div className="f6 f3-ns lh-title tracked-mega tc measure">
                  {img.body}
                </div>
              </div>
            </div>
          </div> 
        )}
      </Slider>
    </div>
  );
}

export default FullSlickSlider

import React, { Component } from 'react'
import InPrintImg from './InPrintImg'

class Embroidery extends Component {
  render() {
    return(
      <main className="mt6">
        <div id="embroidery" className="anchor" />

        <article className="flex flex-column flex-row-l items-start-l mh5-l">
          <section className="flex flex-column justify-around order-1-l order-2">
            <header className="dn db-l mh4-l bl b--mid-gray bw2 pl3 pv1">
              <div className="f4 pv2 mb1 light-silver">Screen Printing & Embroidery Services</div>
              <div className="f3 pb2">Show off your brand with custom apparel</div>
            </header>
            <div className="mv4 mv2-l mh4-l mh3">
              <p className="f4 lh-copy mb0 measure-wide-l measure">
                Offering a combination of embroidered and screen printed apparel in your line is a
                great way to diversify your brand's identity and your company's look.
                It's the perfect long-lasting textured application for art
                on hoodies, crew necks, jackets, caps, beanies and more.
              </p>
            </div>
          </section>
          <div className="relative mw6 order-1 order-2-l">
            <InPrintImg img="embroidery" />
            <header className="absolute bottom-0 left-0 right-0 br--bottom fix-bottom
              bg-gray-75 near-white mr6-l dn-l db">
              <div className="f4 tc light-gray pv3">Screen Printing & Embroidery Services</div>
              <div className="f3 tc pb3">Show off your brand with custom apparel</div>
            </header>
          </div>
        </article>
      </main>
    )
  }
}

export default Embroidery
